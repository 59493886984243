import React, { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../../components/miscellianous/children"
import { BSProjectInvitation } from "../../../dto/beem-shot/BSBimModel/BSProjectInvitation"
import { BSProjectInvitationCreationDto } from "../../../dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"

export const BsModelInvitationContext = React.createContext<BsModelInvitationStore>({} as BsModelInvitationStore)

export default function BSModelInvitationContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { sendInvitation } = useBSBimModel()
  const { bsProjectId, bsVariantId } = useParams()

  const [invitation, setInvitation] = useState<BSProjectInvitation>()
  const [emailUser, setEmailUser] = useState<string>("")
  const [isModelInvitationLoading, setIsModelInvitationLoading] = useState<boolean>(false)

  const sendBSModelInvitation = useCallback(
    async (bsProjectInvitationCreationDto: BSProjectInvitationCreationDto) => {
      setIsModelInvitationLoading(true)
      return sendInvitation(bsProjectInvitationCreationDto)
        .then((bsProjectInvitation) => {
          setInvitation(bsProjectInvitation)
          setEmailUser(bsProjectInvitation.email)
          return bsProjectInvitation
        })
        .finally(() => setIsModelInvitationLoading(false))
    },
    [sendInvitation]
  )

  const bsModelInvitationStore: BsModelInvitationStore = useMemo(
    () => ({
      invitation,
      sendBSModelInvitation,
      emailUser,
      isModelInvitationLoading,
      setIsModelInvitationLoading,
    }),
    [invitation, sendBSModelInvitation, emailUser, isModelInvitationLoading]
  )
  return <BsModelInvitationContext.Provider value={bsModelInvitationStore}>{children}</BsModelInvitationContext.Provider>
}

export type BsModelInvitationStore = {
  invitation: BSProjectInvitation | undefined
  sendBSModelInvitation(bsProjectInvitationCreationDto: BSProjectInvitationCreationDto): Promise<BSProjectInvitation | void>
  emailUser: string
  isModelInvitationLoading: boolean
  setIsModelInvitationLoading: React.Dispatch<React.SetStateAction<boolean>>
}
