import { Box } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSVariant } from "../../../core/dto/beem-shot/BSVariant/BSVariant"
import { useBSVariant } from "../../../core/hooks/beem-shot/useBSVariant"
import { VariantsOverviewList } from "./VariantsOverviewList"
import { VariantsOverwiewHeader } from "./VariantsOverwiewHeader"

interface IProps {
  selectedBsBimModel?: BSBimModel | undefined
  setSelectedBsBimModel?: React.Dispatch<React.SetStateAction<BSBimModel | undefined>>
}

export function VariantsOverview({ selectedBsBimModel, setSelectedBsBimModel }: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId, bsBimModelId } = useParams()
  const { getVariantsByBSBimModelId } = useBSVariant()

  const { file } = useContext(BSModelFileContext)

  const [variantsByBSBimModelId, setVariantsByBSBimModelId] = useState<BSVariant[]>([])

  useEffect(() => {
    if (bsBimModelId && bsBimModelId !== "new") {
      getVariantsByBSBimModelId(bsBimModelId).then((x) => setVariantsByBSBimModelId(x))
    }
  }, [bsBimModelId, file, getVariantsByBSBimModelId])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent="flex-start"
      flexDirection="column"
      alignContent="flex-start"
      alignItems="center"
      borderColor="#ECF3F3"
      my={3}
      sx={{
        backgroundColor: "#F5F8F8",
        height: "70vh",
        overflow: "auto",
        p: 3,
        pt: 0,
        cursor: "default",
      }}>
      <VariantsOverwiewHeader />
      <VariantsOverviewList />
    </Box>
  )
}
