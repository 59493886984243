import { TextField, Typography } from "@mui/material"
import React, { useContext, useState } from "react"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { DeleteDialog } from "../../dialog/delete-dialog"
import { SuccessContext } from "../../layout/success-snackbar"

type IProps = {
  bsBimModel: BSBimModel | undefined
  openDeleteBSBimModel: boolean
  setOpenDeleteBSBimModel: React.Dispatch<React.SetStateAction<boolean>>
}

export function DeleteBSBimModelDialog({
  bsBimModel,
  openDeleteBSBimModel,
  setOpenDeleteBSBimModel,
}: Readonly<IProps>): React.JSX.Element {
  const { handleDeleteBsBimModel } = useContext(BsModelContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [error, setError] = useState<any>({})
  const [bsBimModelNameToDelete, setBsBimModelNameToDelete] = useState<string>("")

  function handleChangeDeleteDialog(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setError({ ...error, bsBimModelNameToDelete: undefined })
    setBsBimModelNameToDelete(target.value)
  }

  function validateProjectNameToDelete(): boolean {
    const newError: Record<string, string> = {}
    let isValid = true
    const nameValue = bsBimModelNameToDelete?.trim()

    if (!nameValue?.length) {
      newError.bsBimModelNameToDelete = "Veuillez remplir ce champ"
      isValid = false
    }

    if (nameValue !== bsBimModel?.fileName) {
      newError.bsBimModelNameToDelete = "Le nom de la maquette est incorrect"
      isValid = false
    }

    if (nameValue.length > 1000) {
      newError.bsBimModelNameToDelete = "1000 caractères maximum"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  return (
    <DeleteDialog
      id="deleteBsBimModel"
      title={`Suppression de la maquette "${bsBimModel?.fileName}"`}
      contentText={
        <>
          <Typography variant="body1" sx={{ fontSize: 19 }}>
            Pour confirmer la suppression, tapez "{bsBimModel?.fileName}" ci-dessous (sans les guillemets)
          </Typography>
          <TextField
            required
            id="bsProjectNameToDelete"
            onChange={handleChangeDeleteDialog}
            value={bsBimModelNameToDelete}
            variant="outlined"
            fullWidth
            error={!!error.bsBimModelNameToDelete}
            helperText={error.bsBimModelNameToDelete}
            sx={{
              mt: 2,
            }}
          />
        </>
      }
      handleClose={() => setOpenDeleteBSBimModel(false)}
      action={() => {
        if (validateProjectNameToDelete() && bsBimModel?.id) {
          handleDeleteBsBimModel(bsBimModel?.id).then(() => {
            openSuccessSnackbar("La maquette a bien été supprimé")
            setOpenDeleteBSBimModel(false)
          })
        }
        return Promise.resolve()
      }}
      open={openDeleteBSBimModel}
    />
  )
}
