import { Box, Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSProjectCardDto } from "../../../core/dto/beem-shot/BSProject/BSProjectCardDto"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { useBSProject } from "../../../core/hooks/beem-shot/useBSProject"
import { resolveUrl } from "../../../core/services/http-service"
import { BSSelectVariantDialog } from "../../../pages/beem-shot/components/BSVariant/BSSelectVariantDialog"
import { BSVariantForm } from "../../../pages/beem-shot/components/BSVariant/BSVariantForm"
import { ImageDisplayer } from "../../image-displayer/image-displayer"
import { DisplayerMode, fileTypeAccepted, UploadImageModal } from "../../image-displayer/upload-image-modal"
import { DefaultTypography } from "../../typography/default-typography"
import { ProjectCardStatus } from "../beem-master/project-status-card"
import { InfoCard } from "../components/infoCard"
import { InputForCard } from "../components/InputForCard"

type IProps = {
  bsProjectCardInfo: BSProjectCardDto
  hasCoverImage: boolean
}

export function BSProjectCard({ bsProjectCardInfo, hasCoverImage }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { fetchCoverImage } = useBSProject()

  const { selectedVariant } = useContext(BSVariantContext)
  const { setBsProject } = useContext(BSProjectContext)

  const [openSelectedVariants, setOpenSelectedVariants] = useState<boolean>(false)
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)
  const [openDisplayerImg, setOpenDisplayerImg] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>()

  useEffect(() => {
    if (bsProjectCardInfo.hasCoverImg) {
      setIsLoading(true)
      fetchCoverImage(bsProjectCardInfo.id)
        .then(async (blob) => {
          if (blob) {
            const newFile = new File([blob], bsProjectCardInfo.coverImgFileName, { type: fileTypeAccepted })
            setFile(newFile)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [
    fetchCoverImage,
    bsProjectCardInfo.coverImgFileName,
    bsProjectCardInfo.id,
    hasCoverImage,
    bsProjectCardInfo.hasCoverImg,
  ])

  const handleClose = (cancelAction?: boolean): void => {
    setOpenSelectedVariants(false)
    if (cancelAction) {
      setBsProject(undefined)
    }
  }

  function handleCloseVariant(cancelAction?: boolean): void {
    setOpenCreateVariantForm(false)
    if (cancelAction) {
      setBsProject(undefined)
    }
  }

  return (
    <>
      <Box
        onClick={() => {
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectCardInfo?.id]))
        }}
        sx={{
          width: "100%",
          minHeight: 100,
          textDecoration: "none",
          color: "inherit",
          cursor: "pointer",
        }}>
        <Grid
          container
          alignItems="center"
          gap={2}
          width="100%"
          sx={{
            backgroundColor: "#fff",
            borderRadius: 3,
            p: 3,
            boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
            transition: "all 0.2s ease-in-out",
            ":hover": {
              cursor: "pointer",
              boxShadow: 10,
              transform: "translateY(-5px)",
            },
          }}>
          <Grid container display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Grid
              item
              xs={2}
              display="flex"
              onClick={(e) => {
                if (file) {
                  e.stopPropagation()
                  setOpenDisplayerImg(true)
                }
              }}
              sx={{
                overflow: "hidden",
              }}>
              <ImageDisplayer file={file} height={70} width={70} isLoading={isLoading} activateAnimation />
            </Grid>

            <Grid display="flex" justifyContent="flex-end" item xs={3}>
              <ProjectCardStatus status={bsProjectCardInfo.status} />
            </Grid>
          </Grid>

          <Grid container display="flex" justifyContent="left" alignItems="center" width="100%">
            <DefaultTypography label={bsProjectCardInfo.name} fontSize="16px" lineHeight="24px" fontWeight={600} />
          </Grid>

          <Grid container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <InputForCard label="N° d'affaire" value={bsProjectCardInfo.businessCode || ""} />
            <InputForCard label="Département" value={`${bsProjectCardInfo.departmentName || ""}`} />
          </Grid>

          <InfoCard
            organizationBusinessName={bsProjectCardInfo.projectOrganizationBusinessName}
            lastModifiedDate={bsProjectCardInfo.lastModifiedDate}
            numberDocument={bsProjectCardInfo.variantNumber ?? "0"}
            teamNumber={bsProjectCardInfo.teamNumber}
          />
        </Grid>
      </Box>

      <BSVariantForm
        open={openCreateVariantForm}
        handleClose={handleCloseVariant}
        bsProjectId={bsProjectCardInfo.id}
        bsVariant={selectedVariant}
      />

      <BSSelectVariantDialog
        open={openSelectedVariants}
        handleClose={handleClose}
        projectName={bsProjectCardInfo.name}
        bsProjectId={bsProjectCardInfo.id}
        isWriting={bsProjectCardInfo.status !== ProjectStatusEnum.ARCHIVED}
      />

      <UploadImageModal
        file={file}
        validateImage
        open={openDisplayerImg}
        handleClose={() => setOpenDisplayerImg(false)}
        isloading
        displayMode={DisplayerMode.UPDATE}
        imageTitle=""
      />
    </>
  )
}
