import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSVariantCardWithSizeDto } from "../../dto/beem-shot/BSVariant/BSProjectCardWithSizeDto"
import { BSVariant } from "../../dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"

type BsVariantHook = {
  createBSVariant(beemShotProjectCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant>
  getBSVariants(projectId: string, page: number): Promise<BSVariantCardWithSizeDto>
  updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant>
  deleteBsVariant(bsVariantId: string): Promise<void>
  getVariantsByBSBimModelId(bsBimModelId: string): Promise<BSVariant[]>
}

export function useBSVariant(): BsVariantHook {
  const { get, put, post } = useHttp()

  return useMemo(
    () => ({
      createBSVariant(bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant> {
        const params = sourceVariantId
          ? [
              {
                key: "sourceVariantId",
                value: sourceVariantId,
              },
            ]
          : []

        return post(appConstants.apiEndpoints.BEEM_SHOT_VARIANT, bsVariantCreationDto, params).then((response) =>
          response.json()
        )
      },
      getBSVariants(projectId: string, page: number): Promise<BSVariantCardWithSizeDto> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT_ALL_VARIANT}`, [
          {
            key: "projectId",
            value: projectId,
          },
          {
            key: "page",
            value: page.toString(),
          },
        ]).then((response) => response.json())
      },
      updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_UPDATE, bsVariantUpdateDto).then((response) =>
          response.json()
        )
      },
      deleteBsVariant(bsVariantId: string): Promise<void> {
        return post(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_DELETE, [bsVariantId]), {}).then()
      },
      getVariantsByBSBimModelId(bsBimModelId: string): Promise<BSVariant[]> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT}/variants-by-bim-model-id`, [
          {
            key: "bsBimModelId",
            value: bsBimModelId,
          },
        ]).then((response) => response.json())
      },
    }),
    [get, post, put]
  )
}
