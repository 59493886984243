import { Grid } from "@mui/material"
import React from "react"
import { DefaultTypography } from "../../typography/default-typography"

type IProps = {
  label: string
  value: string
}

export function InputForCard({ label, value }: Readonly<IProps>): React.JSX.Element {
  return (
    <Grid
      item
      xs={6}
      display="flex"
      flexDirection="column"
      sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
      <DefaultTypography label={label} fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
      <DefaultTypography label={value || ""} fontSize="12px" lineHeight="18px" fontWeight={400} />
    </Grid>
  )
}
