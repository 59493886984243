import { Box, createTheme, CssBaseline, ThemeProvider, useTheme } from "@mui/material"
import React from "react"
import { BSBimModelChoice } from "./BSBimModelChoice"

export function BSBimModelDisplayOrChoicePage(): React.JSX.Element {
  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <Box>
        <BSBimModelChoice />
      </Box>
    </ThemeProvider>
  )
}
