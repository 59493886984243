import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { IconBeemPilot } from "../../../components/custom-icons/icon-beem-pilot"
import { Children } from "../../../components/miscellianous/children"
import { pagesUrl } from "../../appConstants"
import { AppSideBarContext, UrlStateEnum } from "../side-bar/side-bar-context"

export enum NavbarTypeEnum {
  DEFAULT,
  STEP_HEADER,
  HEAD_WITHOUT_STEP,
  UNKNOWN,
}

export interface Pagination {
  label: string
  url?: string
}

export const AppNavBarContext = React.createContext<AppNavBarStore>({} as AppNavBarStore)

export function NavBarContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const location = useLocation()

  const { urlState } = useContext(AppSideBarContext)

  const [title, setTitle] = useState<string>("")
  const [icon, setIcon] = useState<React.JSX.Element | undefined>()
  const [typeNavBar, setTypeNavBar] = useState<NavbarTypeEnum>(NavbarTypeEnum.DEFAULT)
  const previousUrl = useRef<string | undefined>(pagesUrl.CONTROL_BOARD_HOME)
  const actionOnBackButton = useRef<() => void | undefined>()

  const [stepHeader, setStepHeader] = useState<number>(0)
  const [showProjectStatus, setShowProjectStatus] = useState<boolean>(false)
  const [pagination, setPagination] = useState<Pagination[]>([])

  useEffect(() => {
    if (urlState === UrlStateEnum.BEEM_SHOT) {
      setIcon(
        <Link to={pagesUrl.BEEM_SHOT_HOME}>
          <DonutSmallOutlinedIcon fontSize="medium" sx={{ color: "#0BE2A0", fontWeight: "2px" }} />
        </Link>
      )
    } else if (urlState === UrlStateEnum.BEEM_PILOT) {
      setIcon(
        <Link to={pagesUrl.BEEM_PILOT_HOME}>
          <IconBeemPilot />
        </Link>
      )
    }
  }, [urlState])

  const setPreviousUrl = useCallback((newUrl: string | undefined) => {
    previousUrl.current = newUrl
  }, [])

  const setActionOnBackButton = useCallback((newUrl: () => void | undefined) => {
    actionOnBackButton.current = newUrl
  }, [])

  /* reset the navbar when the url change */
  useEffect(
    () => () => {
      setTypeNavBar(NavbarTypeEnum.DEFAULT)
      previousUrl.current = `${location.pathname}${location?.search || ""}`
    },
    [location]
  )

  const AppNavBarStore: AppNavBarStore = useMemo(
    () => ({
      title,
      setTitle,
      icon,
      setIcon,
      typeNavBar,
      setTypeNavBar,
      previousUrl: previousUrl.current,
      setPreviousUrl,
      actionOnBackButton: actionOnBackButton.current,
      setActionOnBackButton,
      stepHeader,
      setStepHeader,
      showProjectStatus,
      setShowProjectStatus,
      pagination,
      setPagination,
    }),
    [icon, pagination, setActionOnBackButton, setPreviousUrl, showProjectStatus, stepHeader, title, typeNavBar]
  )

  return <AppNavBarContext.Provider value={AppNavBarStore}>{children}</AppNavBarContext.Provider>
}

export type AppNavBarStore = {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  icon: React.JSX.Element | undefined
  setIcon: React.Dispatch<React.SetStateAction<React.JSX.Element | undefined>>
  typeNavBar: NavbarTypeEnum
  setTypeNavBar: React.Dispatch<React.SetStateAction<NavbarTypeEnum>>
  previousUrl: string | undefined
  setPreviousUrl(newUrl: string | undefined): void
  actionOnBackButton: (() => void) | undefined
  setActionOnBackButton(newUrl: () => void | undefined): void
  stepHeader: number
  setStepHeader: React.Dispatch<React.SetStateAction<number>>
  showProjectStatus: boolean
  setShowProjectStatus: React.Dispatch<React.SetStateAction<boolean>>
  pagination: Pagination[]
  setPagination: React.Dispatch<React.SetStateAction<Pagination[]>>
}
