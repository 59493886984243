import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { IconFileTypeXml } from "../../../components/custom-icons/icon-file-type-xml"
import { IconRseeShareLink } from "../../../components/custom-icons/icon-rsee-share-link"
import { IconUserCheck } from "../../../components/custom-icons/icon-user-check"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { TabEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { RseeDocumentListContext } from "../../../core/context/beem-pilot/rsee/rsee-document-list-context"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeTeamProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-team-project-context"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { RseeShareLinkPage } from "../rseeShareLink/RseeShareLinkPage"
import { RseeTeamPage } from "../rseeTeam/RseeTeamPage"
import { RseeDocumentList } from "./components/RseeDocumentList"

export function RseeProjectDetailPage(): React.JSX.Element {
  const { rseeProjectId } = useParams()

  const { setShowAppSide } = useContext(AppSideBarContext)
  const { currentTeam } = useContext(RseeTeamProjectContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)

  const { rseeProject, refreshProject } = useContext(RseeProjectContext)
  const { rseeDocumentList } = useContext(RseeDocumentListContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState<TabEnum>(TabEnum.FICHIER_RSEE)

  useEffect(() => {
    if (rseeProjectId) {
      refreshProject()
    }
  }, [refreshProject, rseeProjectId])

  useEffect(() => {
    setPreviousUrl(pagesUrl.BEEM_PILOT_HOME)
    setShowProjectStatus(true)
    setTitle(rseeProject?.projectName ?? "")
    setPagination([
      { label: "Tableau de bord", url: resolveUrl(pagesUrl.BEEM_SHOT_HOME, []) },
      { label: rseeProject?.projectName ?? "" },
    ])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM PILOT")
    }
  }, [
    location,
    rseeProject?.projectName,
    setPagination,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: TabEnum.FICHIER_RSEE,
      Icon: IconFileTypeXml,
      label: "Fichier RSEE",
      chips: rseeDocumentList?.length.toString(),
      content: <RseeDocumentList />,
    },
    {
      id: TabEnum.EQUIPE,
      Icon: IconUserCheck,
      label: "Équipe",
      chips: currentTeam.length.toString(),
      content: <RseeTeamPage />,
    },
    {
      id: TabEnum.LIEN_DE_PARTAGE,
      Icon: IconRseeShareLink,
      label: "Lien de partage",
      chips: undefined,
      content: <RseeShareLinkPage />,
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      {rseeProject && rseeProject.projectStatus !== ProjectStatusEnum.IN_PROGRESS && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            color: "white",
            borderRadius: 4,
            backgroundColor: "#070F27",
          }}>
          Mode lecture
        </Box>
      )}
      <CustomTabPanel panels={tabTest} currentTabId={tabs} handleChange={handleChange} />
    </Box>
  )
}
