import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { Box, Grid, Typography } from "@mui/material"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"

export function BackButtonNavbar(): React.JSX.Element {
  const navigate = useNavigate()

  const { previousUrl, icon, actionOnBackButton } = useContext(AppNavBarContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { bsProject } = useContext(BSProjectContext)

  return (
    <Grid container item xs={2} display="flex" flexDirection="row" alignContent="center" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center" overflow="hidden">
        <ArrowBackOutlinedIcon
          onClick={() => {
            if (previousUrl) {
              navigate(previousUrl)
            } else if (actionOnBackButton) {
              actionOnBackButton()
            }
          }}
          fontSize="medium"
          sx={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          alignItems="center"
          sx={{ borderRadius: 2, background: "#b7ffe3", p: 1, m: 1, border: 3, borderColor: "white" }}>
          {icon}
        </Box>
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {rseeProject?.projectName}
          </Typography>
          <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {selectedVariant?.name}
          </Typography>

          <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {bsProject?.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
