import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSBimModel } from "../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelCreateOrUpdate } from "../../dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { BSProjectInvitation } from "../../dto/beem-shot/BSBimModel/BSProjectInvitation"
import { BSProjectInvitationCreationDto } from "../../dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"
import { streamToBlob } from "../../services/file-service"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"

type BimModelHook = {
  sendBsBimModel(bsModelForm: BSBimModelCreateOrUpdate, bsProjectId: string): Promise<BSBimModel>
  sendBsFile(bsbimModelFile: File, bsbimModelId: string): Promise<BSBimModel>
  fetchBSBimModelFile(bsBimModelId: string): Promise<Blob | undefined>
  fetchBimModelById(bsBimModelId: string): Promise<BSBimModel | undefined>
  fetchAllBimModelByProjectId(bsProjectId: string): Promise<BSBimModel[]>
  sendInvitation(bsProjectInvitationCreationDto: BSProjectInvitationCreationDto): Promise<BSProjectInvitation>
  deleteBSBimModelFile(bsBimModelId: string): Promise<void>
}

export function useBSBimModel(): BimModelHook {
  const { get, post, postFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      sendBsBimModel(bsModelForm: BSBimModelCreateOrUpdate, bsProjectId: string): Promise<BSBimModel> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, bsModelForm, [
          {
            key: "bsProjectId",
            value: bsProjectId,
          },
        ])
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      sendBsFile(bsbimModelFile: File, bsbimModelId: string): Promise<BSBimModel> {
        const formData = new FormData()
        formData.append("ifcFile", bsbimModelFile)
        formData.append("bsbimModelId", bsbimModelId)
        return postFile(`${appConstants.apiEndpoints.BEEM_SHOT}/file`, formData)
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      fetchBSBimModelFile(bsBimModelId): Promise<Blob | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_FILE, [bsBimModelId]))
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
      fetchBimModelById(bsBimModelId: string): Promise<BSBimModel | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_ID, [bsBimModelId]))
          .then((r) => r.json())
          .then((data: any) => BSBimModel.fromDto(data))
          .catch(() => undefined)
      },
      fetchAllBimModelByProjectId(bsProjectId: string): Promise<BSBimModel[]> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_ALL_BIM_MODEL_ID, [bsProjectId]))
          .then((r) => r.json())
          .then((data: BSBimModel[]) => data.map((x) => BSBimModel.fromDto(x)))
      },
      sendInvitation(bsProjectInvitationCreationDto: BSProjectInvitationCreationDto): Promise<BSProjectInvitation> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_INVITATION, [], {}),
          bsProjectInvitationCreationDto,
          []
        )
          .then((r) => r.json())
          .then((invitation: BSProjectInvitation) => invitation)
      },
      sendBackInvitation(bsProjectId: string, bsInvitationId: string): Promise<void> {
        return post(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_SEND_BACK_INVITATION, [bsProjectId]), {}, [
          {
            key: "bsInvitationId",
            value: bsInvitationId,
          },
        ]).then()
      },
      cancelInvitation(bsInvitationId: string): Promise<void> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_CANCEL_INVITATION, [bsInvitationId]),
          {},
          []
        ).then()
      },

      deleteBSBimModelFile(bsBimModelId: string): Promise<void> {
        return deleteRequest(resolveUrl(`${appConstants.apiEndpoints.BEEM_SHOT}/bs-bim-model-file`, []), [
          {
            key: "bsBimModelId",
            value: bsBimModelId,
          },
        ]).then()
      },
    }),
    [deleteRequest, get, post, postFile]
  )
}
