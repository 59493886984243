import { Box } from "@mui/material"
import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelInfo } from "./components/BSBimModelInfo"
import { BSBimModelHeader } from "./components/BSBimModelSelectHeader"

interface IProps {
  selectedBsBimModel: BSBimModel | undefined
  setSelectedBsBimModel: React.Dispatch<React.SetStateAction<BSBimModel | undefined>>
}

export function BSBimModelDisplayInformation({
  selectedBsBimModel,
  setSelectedBsBimModel,
}: Readonly<IProps>): React.JSX.Element {
  const location = useLocation()

  const { bsBimModelListByProject } = useContext(BsModelContext)
  const { bsInput } = useContext(BSInputContext)

  useEffect(() => {
    const state = location?.state?.addedBimModelId
    if (state) {
      const addedModel = bsBimModelListByProject.find((bimModel) => bimModel.id === location?.state?.addedBimModelId)
      setSelectedBsBimModel(addedModel)
    } else {
      const affectedBsBimModel = bsBimModelListByProject.find((bimModel) => bimModel.id === bsInput?.bsBimModelId)
      setSelectedBsBimModel(affectedBsBimModel)
    }
  }, [bsBimModelListByProject, bsInput?.bsBimModelId, location?.state?.addedBimModelId, setSelectedBsBimModel])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent="flex-start"
      flexDirection="column"
      alignContent="flex-start"
      alignItems="center"
      borderColor="#ECF3F3"
      my={3}
      sx={{
        backgroundColor: "#F5F8F8",
        height: "70vh",
        overflow: "auto",
        p: 3,
        pt: 0,
        cursor: "default",
      }}>
      <BSBimModelHeader
        title="Informations de la maquette"
        subTitle="Vérifiez les informations de la maquette sélectionnée. Vous pouvez ajuster les données si nécessaire afin de garantir 
  un calcul précis de l'impact carbone."
      />

      <Box p={2} mt={3} borderRadius={2} width="100%" boxShadow={3} sx={{ backgroundColor: "white" }}>
        <BSBimModelInfo selectedBsBimModel={selectedBsBimModel} verticalAlignment />
      </Box>
    </Box>
  )
}
