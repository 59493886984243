import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { TableauDashboard } from "../../../../components/TableauDashboard/TableauDashboard"
import { pagesUrl } from "../../../../core/appConstants"
import { BsModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { ModuleTypeEnum } from "../../../../core/enum/bimModel/ModuleTypeEnum"
import { useBimModel } from "../../../../core/hooks/bim-model/use-bim-model"
import { getEnvProperty, PROPERTIES } from "../../../../core/services/environment-service"
import { resolveUrl } from "../../../../core/services/http-service"
import {
  AUTH_TOKEN_PARAMETER_NAME,
  PROJECT_ID_PARAMETER_NAME,
  VARIANT_ID_PARAMETER_NAME,
} from "../../../../core/services/tableau/tableau-extensions-service"
import { TableauViz } from "../../../../core/services/tableau/tableau-service"

export function BSDashboard(): React.JSX.Element {
  const { createBimModelAuthToken } = useBimModel()

  const { selectedVariant } = useContext(BSVariantContext)
  const { bsProject } = useContext(BSProjectContext)
  const { bsBimModel } = useContext(BsModelContext)

  const navigate = useNavigate()

  const [bimModelAuthToken, setBimModelAuthToken] = useState<string | undefined>()
  const [projectId, setProjectId] = useState<string | undefined>()
  const [variantId, setVariantId] = useState<string | undefined>()
  const [isError, setIsError] = useState<boolean>(false)
  const viz = useRef(new TableauViz())

  const environment: string = useMemo(() => getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT), [])

  const url = useMemo(() => {
    console.info("environment", environment)
    if (environment === "production") {
      return "https://dub01.online.tableau.com/t/timetobeemtableau/views/bsResult_v1_prod/BS_Result"
    } else {
      return "https://dub01.online.tableau.com/t/timetobeemtableau/views/bsResult_v1_qa/BS_Result"
    }
  }, [environment])

  useEffect(() => {
    if (bsProject?.id) {
      if (environment === "local") {
        setProjectId("66a254678de9457bad2466cd")
      } else {
        setProjectId(bsProject.id)
      }
      if (selectedVariant?.id) {
        if (environment === "local") {
          setVariantId("66a26d2db516812b08ff843c")
        } else {
          setVariantId(selectedVariant.id)
        }
      }
    }
  }, [bsProject?.id, environment, selectedVariant?.id])

  useEffect(() => {
    if (bsBimModel?.id) {
      const bsBimModelId: string = bsBimModel.id

      createBimModelAuthToken(bsBimModelId, ModuleTypeEnum.BEEM_SHOT)
        .then((bimModelToken) => {
          let authToken: string
          if (environment === "local") {
            authToken = "61L2lBfBjFe5gxovZBcOXuk22KY"
          } else {
            authToken = bimModelToken.authToken
          }
          setBimModelAuthToken(authToken)
        })
        .catch((e) => {
          setIsError(true)
          throw e
        })
    }
  }, [bsBimModel?.id, createBimModelAuthToken, environment])

  const renderContent = useCallback((): React.JSX.Element => {
    if (url && projectId && variantId && bimModelAuthToken) {
      return (
        <Box sx={{ height: "2500px" }}>
          <TableauDashboard
            dashboardUrl={url}
            isDataReady
            tableauParameter={[
              { name: PROJECT_ID_PARAMETER_NAME, value: [projectId] },
              { name: VARIANT_ID_PARAMETER_NAME, value: [variantId] },
              { name: AUTH_TOKEN_PARAMETER_NAME, value: [bimModelAuthToken] },
            ]}
            filterToAdd={[]}
            vizRef={viz}
          />
        </Box>
      )
    } else if (isError) {
      return <Typography>Erreur: le tableau n'a pas pu être récupéré</Typography>
    } else {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" alignContent="center" height="60vh" width="100%">
          <CircularProgress />
        </Box>
      )
    }
  }, [bimModelAuthToken, isError, projectId, url, variantId])

  return (
    <Box
      onDoubleClick={() => undefined}
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      height="100%"
      sx={{ userSelect: "none" }}>
      {renderContent()}
      <CustomBottomNavigation
        actionButton={() =>
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProject?.id, selectedVariant?.id, bsBimModel.id]))
        }
        actionLabel="Mettre à jour le calcul"
      />
    </Box>
  )
}
