export enum TabEnum {
  // RseeProjectListContainer
  RSEE_PROJECTS,
  PILOTAGE_GENERAL,

  //RseeProjectDetailPage
  FICHIER_RSEE,
  EQUIPE,
  LIEN_DE_PARTAGE,

  // BSProjectDetailsPage
  BS_VARIANTS,
  BS_BIM_MODEL_POOL,
  BS_COMPARE_VARIANTES,

  // BSVariantDetailPage
  BS_PROJECT,
  TABLEAU_DE_BORD,
  BS_DETAIL_CALCUL,

  // BSProjectListContainer
  BS_PROJECTS_LIST,

  // OrganizationPage
  ORGANIZATION_INFO,
  ORGANIZATION_COLLAB,
  ORGANIZATION_CONTACT,
  ORGANIZATION_KARNIAL_PROJECTS,
}

export enum TabPageEnum {
  VARIANT_ACTIVE_TAB,
  PROJECT_ACTIVE_TAB,
}

export const tabEnumToIndex: Record<TabEnum, number> = {
  [TabEnum.RSEE_PROJECTS]: 0,
  [TabEnum.PILOTAGE_GENERAL]: 1,

  [TabEnum.FICHIER_RSEE]: 0,
  [TabEnum.EQUIPE]: 1,
  [TabEnum.LIEN_DE_PARTAGE]: 2,

  [TabEnum.BS_VARIANTS]: 0,
  [TabEnum.BS_BIM_MODEL_POOL]: 1,
  [TabEnum.BS_COMPARE_VARIANTES]: 2,

  [TabEnum.BS_PROJECT]: 0,
  [TabEnum.TABLEAU_DE_BORD]: 1,
  [TabEnum.BS_DETAIL_CALCUL]: 2,

  [TabEnum.BS_PROJECTS_LIST]: 0,

  [TabEnum.ORGANIZATION_INFO]: 0,
  [TabEnum.ORGANIZATION_COLLAB]: 1,
  [TabEnum.ORGANIZATION_CONTACT]: 2,
  [TabEnum.ORGANIZATION_KARNIAL_PROJECTS]: 3,
}

interface CurrentTab {
  tab: TabEnum | undefined
  selectedId: string | undefined // This can be project or variant id.
}

export function getStoredTab(page: TabPageEnum): CurrentTab | undefined {
  const currentTabStr: string | null = localStorage.getItem(toStoreKey(page))
  if (currentTabStr !== null) {
    const currentTab: CurrentTab | undefined = JSON.parse(currentTabStr)
    if (currentTab && currentTab.tab) {
      return currentTab
    }
  }
  return undefined
}

export function storeTab(tab: CurrentTab | undefined, page: TabPageEnum): void {
  if (tab) {
    localStorage.setItem(toStoreKey(page), JSON.stringify(tab))
  } else {
    localStorage.removeItem(toStoreKey(page))
  }
}

function toStoreKey(page: TabPageEnum): string {
  return `tab_${page.toString()}`
}
