import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SearchIcon from "@mui/icons-material/Search"
import TuneIcon from "@mui/icons-material/Tune"
import { Box, Drawer, Grid, IconButton, InputBase, Switch, Typography, Zoom } from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import city from "../../../../../assets/img/empty-result-city.png"
import { RseeProjectCard } from "../../../../components/card/rsee/RseeProjectCard"
import { IconFilter } from "../../../../components/custom-icons/icon-filter"
import { BaseSelectInput } from "../../../../components/inputs/select-input/BaseSelectInput"
import { RseeProjectListContext } from "../../../../core/context/beem-pilot/rsee/RseeProjectListContext"
import { UserContext } from "../../../../core/context/user/user-context"
import { RseeProjectCardDto } from "../../../../core/dto/rsee/rsee-project-card"
import { ProjectStatusEnum, projectStatusEnumLabels } from "../../../../core/enum/projectStatusEnum"
import { isMegaUser } from "../../../../core/services/authentication-service"
import { enumToSelectOptions } from "../../../../core/services/helper-service"

const ITEMS_PER_PAGE = 6

export function RseeProjectList(): React.JSX.Element {
  const {
    triggerSearch,
    rseeProjectList,
    statusFilter,
    setStatusFilter,
    fetchOnlyMyRseeProject,
    setFetchOnlyMyRseeProject,
    setSearchState,
    searchState,
  } = useContext(RseeProjectListContext)
  const { user } = useContext(UserContext)

  const [openDrawerFilter, setOpenDrawerFilter] = React.useState(false)
  const [page, setPage] = useState(1)

  const isOrganizationMegaUser = isMegaUser(user)

  const startIndex = useMemo(() => (page - 1) * ITEMS_PER_PAGE, [page])
  const endIndex = useMemo(() => startIndex + ITEMS_PER_PAGE, [startIndex])

  function handleSelectChange(value: ProjectStatusEnum): void {
    setStatusFilter(value)
  }

  function handleSearch(newSearchState: string): void {
    setPage(1)
    setSearchState(newSearchState)
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" sx={{ pt: 2 }}>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="row"
        rowGap={1}
        width="100%"
        sx={{ py: 1 }}>
        <Grid
          item
          xs={5}
          component="form"
          onSubmit={triggerSearch}
          sx={{
            flex: 3,
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ffffff",
            borderRadius: 3,
          }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={searchState}
            placeholder="Rechercher par nom, numéro d’affaire"
            inputProps={{ "aria-label": "search" }}
            endAdornment={
              <IconButton type="submit">
                <SearchIcon sx={{ margin: "0 8px" }} />
              </IconButton>
            }
            onChange={(e: { target: { value: any } }) => {
              handleSearch(e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={2} display="flex" flexDirection="row" alignItems="center">
          <BaseSelectInput
            id="rseeStatusFilter"
            label="Statut"
            selectedOption={statusFilter}
            options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels).filter(
              (selectedOption) => selectedOption.value !== ProjectStatusEnum.DELETED
            )}
            mode="direct"
            handleChange={(e) => handleSelectChange(e)}
            isBeemShot
            size="small"
          />
          {isOrganizationMegaUser && (
            <IconButton onClick={() => setOpenDrawerFilter(true)} sx={{ background: "#C9FFE8", borderRadius: 2, ml: 2 }}>
              <TuneIcon fontSize="medium" sx={{ color: "#009069" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid container columnSpacing={4} alignItems="flex-start" height="100%" rowGap={3}>
        {rseeProjectList?.length > 0 ? (
          rseeProjectList
            .slice(startIndex, endIndex)
            .sort((a, b) => b.projectStatus.localeCompare(a.projectStatus))
            .map((rseeProject: RseeProjectCardDto, index) => (
              <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={rseeProject.projectId}>
                <Grid item xs={12} md={4} lg={4} key={rseeProject.projectId}>
                  <RseeProjectCard key={rseeProject.projectId} rseeProjectCard={rseeProject} />
                </Grid>
              </Zoom>
            ))
        ) : (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%">
            <Typography fontSize="24px" fontWeight={600} lineHeight="36px" textAlign="center" color="#374771">
              Aucun résultat
            </Typography>
            <Typography variant="body1">Modifier les options de recherche.</Typography>
            <Box sx={{ p: 2 }}>
              <img
                src={city}
                alt={city}
                height="300px"
                width="max-content"
                style={{
                  marginLeft: 15,
                  marginTop: -8,
                }}
              />
            </Box>
          </Box>
        )}
      </Grid>

      <Drawer
        open={openDrawerFilter}
        onClose={() => setOpenDrawerFilter(false)}
        anchor="right"
        PaperProps={{
          style: {
            backgroundColor: "#F5F8F8",
          },
        }}>
        <Box width="55vh">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" p={2}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ borderRadius: 2, background: "#b7ffe3", p: 1, border: 3, borderColor: "white" }}>
                <IconFilter />
              </Box>

              <Typography variant="h6"> Filtrer</Typography>
            </Box>
            <ArrowForwardIcon onClick={() => setOpenDrawerFilter(false)} sx={{ cursor: "pointer" }} />
          </Box>
          <Box m={3} p={1} boxShadow="2px 4px 20px 0px rgba(0, 0, 0, 0.05)" borderRadius={3} sx={{ background: "white" }}>
            {isOrganizationMegaUser && (
              <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                <Switch
                  checked={fetchOnlyMyRseeProject}
                  onChange={() => setFetchOnlyMyRseeProject(!fetchOnlyMyRseeProject)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ marginLeft: "-10px" }}
                />
                <Typography variant="body2">Voir uniquement mes projets</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}
