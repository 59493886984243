import AddToQueueIcon from "@mui/icons-material/AddToQueue"
import CodeIcon from "@mui/icons-material/Code"
import { Button, CircularProgress, Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { FormEvent, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import NavigationDialog from "../../../../components/dialog/navigation-dialog"
import { CachingHelper } from "../../../../components/ifc-displayer/helpers/CachingHelper"
import { SubsetInfo } from "../../../../components/ifc-displayer/models/SubsetInfo"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { BimModelContext } from "../../../../core/context/bim-model/bim-model-context"
import { BimModelFileContext, BimModelFileStore } from "../../../../core/context/bim-model/bim-model-file-context"
import {
  BimModelImportContext,
  BimModelImportContextStore,
} from "../../../../core/context/bim-model/bim-model-import-context"
import { ProjectContext } from "../../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../../core/context/selected-phase-context"
import { ProjectRoleContext } from "../../../../core/context/user/project-role-context"
import { BimModel } from "../../../../core/dto/bim-model/bim-model"
import { IBimModelForm, IBimModelFormKey } from "../../../../core/dto/bim-model/bim-model-form"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { CodeExtraitCreation } from "../../../../core/dto/code-extrait/code-extrait-creation"
import { codeStateEnum } from "../../../../core/enum/codeStateEnum"
import { PhaseEnum } from "../../../../core/enum/phaseEnum"
import { ProjectStatusEnum } from "../../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../../core/enum/roleEnum"
import { useBimModel } from "../../../../core/hooks/bim-model/use-bim-model"
import { useParseBim } from "../../../../core/hooks/bim-model/use-parse-bim"
import { resolveUrl } from "../../../../core/services/http-service"
import { zipFile } from "../../../../core/services/zip-services"
import { BimModelAnalyzer } from "../components/bim-model-analyzer/bim-model-analyzer"
import BimModelForm from "./components/bim-model-form"
import { BimModelUploader } from "./components/bim-model-uploader"

function dtoToForm(bimModel: BimModel): IBimModelForm {
  return {
    projectId: bimModel.projectId,
    fileName: bimModel.fileName,
    version: bimModel.version,
    lots: bimModel.lots,
    surfaceHabitable: bimModel.surfaceHabitable === undefined ? "" : `${bimModel.surfaceHabitable}`,
    surfacePlancher: bimModel.surfacePlancher === undefined ? "" : `${bimModel.surfacePlancher}`,
    empriseAuSol: bimModel.empriseAuSol === undefined ? "" : `${bimModel.empriseAuSol}`,
    surfaceComble: bimModel.surfaceComble === undefined ? "" : `${bimModel.surfaceComble}`,
    surfaceMoyenneLogement: bimModel.surfaceMoyenneLogement === undefined ? "" : `${bimModel.surfaceMoyenneLogement}`,
    modelHashFile: bimModel.modelHashFile,
    phase: bimModel.phase || PhaseEnum.ESQ,
  }
}

function formToDto(originBimModel: BimModel, bimModelForm: IBimModelForm): BimModel {
  return BimModel.fromDto({
    ...originBimModel,
    projectId: bimModelForm.projectId,
    fileName: bimModelForm.fileName,
    version: bimModelForm.version,
    lots: bimModelForm.lots,
    surfaceHabitable: parseFloat(bimModelForm.surfaceHabitable),
    surfacePlancher: parseFloat(bimModelForm.surfacePlancher),
    empriseAuSol: parseFloat(bimModelForm.empriseAuSol),
    surfaceComble: parseFloat(bimModelForm.surfaceComble),
    surfaceMoyenneLogement: parseFloat(bimModelForm.surfaceMoyenneLogement),
    modelHashFile: bimModelForm.modelHashFile,
    phase: bimModelForm.phase,
  })
}

/**
 * This component is the page to import a bim model into a project for a phase
 *
 * @constructor
 */
export function BimModelUploadPage(): React.JSX.Element {
  const navigate = useNavigate()
  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { bimModel, setBimModel } = useContext(BimModelContext)
  const { file, loading, setLoading, setFile, isCompressed } = useContext<BimModelFileStore>(BimModelFileContext)
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { viewer, codesExtraits, setCodesExtraits } = useContext<BimModelImportContextStore>(BimModelImportContext)
  const { sendBimModel, sendFile, postCodeExtraitsForBimModel } = useBimModel()

  const [progress, setProgress] = useState<number>(0)

  const { analyzeModel } = useParseBim({ version: project.codesVersion, setProgress })

  const [bimModelForm, setBimModelForm] = useState<IBimModelForm>(dtoToForm(bimModel))
  const [bimModelFile, setBimModelFile] = useState<File | undefined>(undefined)
  const [activeStep, setActiveStep] = React.useState(0)
  const [error, setError] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const isWriting: boolean =
    hasRole([RoleEnum.ARCHITECT, RoleEnum.ADMINISTRATOR]) && project.status !== ProjectStatusEnum.ARCHIVED

  const steps = ["Importation du fichier .ifc", "Vérification des codes ACV"]

  const isFormUpdateRef = useRef(false)
  const isModelUpdateRef = useRef(false)

  const { organizationId } = useParams()

  useEffect(() => {
    if (file && bimModel?.id) {
      setBimModelFile(file)
    }
  }, [file, bimModel?.id])

  useEffect(() => {
    if (bimModel?.id) {
      setBimModelForm(dtoToForm(bimModel))
    }
  }, [bimModel])

  useEffect(() => {
    if (progress === 100 && activeStep === 0 && codesExtraits.length === 0 && bimModelFile) {
      setIsDialogOpen(true)
    }
  }, [activeStep, codesExtraits, bimModelFile, progress])

  function handleNext(): void {
    if (activeStep === 0) {
      if (validate()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      if (isOrganizationUltimateUser) {
        navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project.id]))
      } else {
        navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
      }
    }
  }

  function handleBack(): void {
    if (activeStep === 0) {
      if (isOrganizationUltimateUser) {
        navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project.id]))
      } else {
        navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  function cancelUpload(): void {
    setBimModelFile(undefined)
    setActiveStep(0)
    setCodesExtraits([])
    setProgress(0)
    isModelUpdateRef.current = false
  }

  function validateAndFormat(): boolean {
    if (bimModelFile === undefined) {
      openErrorSnackbar(new Error("Aucun fichier n'est sélectionné."))
      return false
    }

    if (project?.id) {
      bimModelForm.projectId = project.id
    } else {
      openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
      return false
    }

    if (selectedPhase) {
      bimModelForm.phase = selectedPhase
    } else {
      openErrorSnackbar(new Error("Erreur: Aucune phase projet n'est séléctionnée. Selectionnez la phase actuelle."))
      return false
    }

    return true
  }

  async function sendBimModelInformation(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    setIsSubmitting(true)
    if (!hasRole([RoleEnum.ARCHITECT, RoleEnum.ADMINISTRATOR])) {
      isOrganizationUltimateUser
        ? navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project.id]))
        : navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
      setIsSubmitting(false)
      return
    }

    if (!validateAndFormat()) {
      setIsSubmitting(false)
      return
    }

    if (!bimModelFile) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de bimFile de chargé"))
      setIsSubmitting(false)
      return
    }

    // set the bimModel file name and hash
    if (bimModelFile) {
      bimModelForm.fileName = bimModelFile.name
      // set the cache and also cache the file
      bimModelForm.modelHashFile = await CachingHelper.cacheFile(bimModelFile)
    }

    const codesExtraitsCreation: CodeExtraitCreation[] = codesExtraits
      .filter((codeExtrait) => codeExtrait.errors.length === 0)
      .map((item) => CodeExtraitCreation.fromCodeExtrait(item))

    if (codesExtraitsCreation.length === 0) {
      openErrorSnackbar(new Error("La maquette doit contenir des codes valides"))
      setIsSubmitting(false)
      return
    }

    if (isModelUpdateRef.current) {
      sendBimModelAndFile(bimModelFile, codesExtraitsCreation)
    } else if (isFormUpdateRef.current) {
      sendOnlyBimModelForm()
    } else {
      setIsSubmitting(false)
      navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
    }
  }

  function sendOnlyBimModelForm(): void {
    sendBimModel(formToDto(bimModel, bimModelForm))
      .then((response: BimModel) => {
        if (response.id === undefined) {
          throw new Error("Le retour du serveur ne contient pas d'id bimModel")
        }
        setBimModel(response)
        return response.id
      })
      .then(() => {
        openSuccessSnackbar("Les informations ont été mises à jour avec succès")
        navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  function sendBimModelAndFile(fileToSend: File, codesExtraitsCreation: CodeExtraitCreation[]): void {
    setLoading(true)
    sendBimModel(formToDto(bimModel, bimModelForm))
      .then((response: BimModel) => {
        if (response.id === undefined) {
          throw new Error("Le retour du serveur ne contient pas d'id bimModel")
        }
        setBimModel(response)
        return response.id
      })
      .then((bimModelId) => {
        if (file?.name?.endsWith(".ifczip") && !isCompressed) {
          zipFile(file, setFile)
        }
        return Promise.all([
          sendFile(fileToSend, bimModelId),
          postCodeExtraitsForBimModel(bimModelId, codesExtraitsCreation),
        ])
      })
      .then(() => {
        setFile(bimModelFile)
        CachingHelper.cacheFile(fileToSend)
        openSuccessSnackbar("Le modèle et ses informations ont été chargés avec succès")
      })
      .then(() => {
        navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id]))
      })
      .finally(() => {
        setIsSubmitting(false)
        setLoading(false)
      })
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!bimModelForm?.version || bimModelForm.version === "") {
      newError.version = "Veuillez remplir ce champ"
      isValid = false
    }

    if (!checkSurface("surfaceHabitable", newError)) {
      isValid = false
    }
    if (!checkSurface("surfacePlancher", newError)) {
      isValid = false
    }
    if (!checkSurface("empriseAuSol", newError)) {
      isValid = false
    }

    if (bimModelForm?.surfaceComble === undefined) {
      newError.surfaceComble = "Veuillez remplir ce champ"
      isValid = false
    }

    if (progress !== 100) {
      openErrorSnackbar(new Error("Veuillez attendre la fin du téléchargement "))
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function checkSurface(fieldName: IBimModelFormKey, newError: Record<string, any>): boolean {
    const value = bimModelForm[fieldName]
    if (value === undefined) {
      newError[fieldName] = "Veuillez remplir ce champ"
      return false
    } else if (bimModelForm[fieldName] === "0") {
      newError[fieldName] = "La surface ne doit pas être nulle"
      return false
    }
    return true
  }

  function getTitle(): string {
    if (activeStep === 0) {
      return "Importation"
    } else if (activeStep === 1) {
      return "Code ACV"
    }
    return ""
  }

  function handleClickAcv(codeExtract: CodeExtrait, disableViewerHilight?: boolean): void {
    if (
      !disableViewerHilight &&
      `${codeExtract.code + codeExtract.occurence}` !==
        `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
    )
      viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtract)
    setSelectedCodeExtrait(codeExtract)
  }

  function handleClickCodeManquant(codeManquant: SubsetInfo): void {
    setSelectedCodeExtrait(undefined)
    viewer?.manager.subsetsManager.toggleSubsetHighlight(codeManquant)
  }

  function handleCodeVariantChange(codeVariant: codeStateEnum, unhighlight?: boolean): void {
    viewer?.manager.subsetsManager.updateCodeState(codeVariant, unhighlight ?? false)
    if (unhighlight) setSelectedCodeExtrait(undefined)
  }

  function reset(): void {
    setBimModelFile(undefined)
    setActiveStep(0)
    setCodesExtraits([])
  }

  function submitButton(): React.JSX.Element {
    return isSubmitting ? (
      <CircularProgress />
    ) : (
      <Button disabled={codesExtraits?.length === 0} variant="contained" type="submit">
        Suivant
      </Button>
    )
  }

  function displayNavigationDialog(): React.JSX.Element | null {
    if (codesExtraits.length === 0 && bimModelFile) {
      return <NavigationDialog handleClose={() => setIsDialogOpen(false)} open={isDialogOpen} />
    }
    return null
  }

  return (
    <Box sx={{ pl: 20, pr: 20 }} component="form" onSubmit={sendBimModelInformation}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pt: 4, mb: 5 }}>
        <Box display="flex" flexDirection="row">
          {activeStep === 0 && <AddToQueueIcon fontSize="large" sx={{ mt: 2, mr: 4 }} />}
          {activeStep === 1 && <CodeIcon fontSize="large" sx={{ mt: 2, mr: 4 }} />}

          <Box sx={{ display: "block" }}>
            <Typography variant="h2" gutterBottom color="primary" sx={{ pb: 0, mb: 0 }}>
              <b>{getTitle()}</b>
            </Typography>
            <Typography variant="subtitle1" sx={{ pt: 0, mt: 0 }}>
              {project.name !== "" && project.name !== undefined ? project.name : "Pas de projet selectionné"}
              {selectedPhase ? `  (${selectedPhase})` : ""}
            </Typography>
          </Box>
        </Box>

        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box display="flex">
          <Button
            variant="outlined"
            onClick={() => {
              handleBack()
            }}
            sx={{ mr: 4 }}>
            Retour
          </Button>
          {activeStep === 0 && (
            <Button variant="contained" onClick={handleNext} disabled={bimModelFile === undefined || progress !== 100}>
              Suivant
            </Button>
          )}
          {activeStep === 1 && submitButton()}
        </Box>
      </Box>

      {activeStep === 0 && (
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <BimModelForm
                bimModelForm={bimModelForm}
                setBimModelForm={setBimModelForm}
                error={error}
                setError={setError}
                isFormUpdateRef={isFormUpdateRef}
                isWriting={isWriting}
              />
            </Grid>
            <Grid item md={6}>
              {loading && (
                <Paper
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <CircularProgress />
                  <Typography>Début du chargement de l'ancien modèle</Typography>
                </Paper>
              )}
              {!loading && (
                <BimModelUploader
                  bimModelFile={bimModelFile}
                  setBimModelFile={setBimModelFile}
                  progress={progress}
                  setProgress={setProgress}
                  setActiveStep={setActiveStep}
                  setCodesExtraits={setCodesExtraits}
                  cancelUpload={cancelUpload}
                  isModelUpdateRef={isModelUpdateRef}
                  isWriting={isWriting}
                />
              )}
            </Grid>
          </Grid>
          {!loading && displayNavigationDialog()}
        </Box>
      )}

      {bimModel !== undefined && (
        <Box visibility={activeStep === 1 ? "visible" : "hidden"}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: "flex", flexDirection: "column", height: "0" }}>
                {bimModelFile !== undefined && (
                  <BimModelAnalyzer
                    isTree
                    reset={reset}
                    bimModelFile={bimModelFile}
                    analyzeModel={analyzeModel}
                    codesExtraits={codesExtraits}
                    setProgress={setProgress}
                    handleClickAcv={handleClickAcv}
                    selectedCodeExtrait={selectedCodeExtrait}
                    handleClickCodeManquant={handleClickCodeManquant}
                    handleCodeVariantChange={handleCodeVariantChange}
                    cancelUpload={cancelUpload}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}
