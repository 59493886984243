import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material"
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { TabEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BsModelInvitationContext } from "../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { BSProjectInvitationCreationDto } from "../../../core/dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"
import { useForm } from "../../../core/hooks/form/use-form"
import { required } from "../../../core/hooks/form/validation"
import { resolveUrl } from "../../../core/services/http-service"
import { BSBimModelInviter } from "../components/BSBimModelInviter"
import BSUploader from "../components/BSUploader"
import { MissingInfoCode } from "../components/MissingInfoCode"

interface IForm {
  projectId?: string
  userOrganizationName: string
  lastName: string
  cognitoUserId: string
}

function dtoToForm(): IForm {
  return {
    projectId: "",
    userOrganizationName: "",
    lastName: "",
    cognitoUserId: "",
  }
}

function formToDto(form: IForm, bsProjectId: string): BSProjectInvitationCreationDto {
  return {
    projectId: bsProjectId,
    cognitoUserId: form.cognitoUserId,
  }
}

export function BSUploadPage(): React.JSX.Element {
  const navigate = useNavigate()

  const { bsProjectId, bsBimModelId } = useParams()
  const { bsProject } = useContext(BSProjectContext)
  const { file, isModelFileLoading, isModelFileLoaded } = useContext(BSModelFileContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { invitation, sendBSModelInvitation, isModelInvitationLoading } = useContext(BsModelInvitationContext)
  const { refreshBSInput } = useContext(BSInputContext)

  const [value, setValue] = useState<number>(0)
  const [bimModelFile, setBimModelFile] = useState<File | undefined>(undefined)

  useEffect(() => {
    if (file && bsBimModelId !== "new") {
      setBimModelFile(file)
    }
  }, [bsBimModelId, file])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE, [bsProjectId, bsBimModelId]))
    setStepHeader(bsBimModelId === "new" ? 0 : 1)
    localStorage.setItem("activeTab", TabEnum.BS_BIM_MODEL_POOL.toString())
  }, [bsBimModelId, bsProjectId, setPreviousUrl, setStepHeader, setTypeNavBar])

  function handleChangeTabs(event: React.SyntheticEvent, newValue: number): void {
    setValue(newValue)
  }

  function getBottomButtomLabel(): string {
    if (value === 0) return "importer la maquette paramétrée"
    if (value === 1) return "envoyer l'invitation"
    return ""
  }

  function getBottomButtomAction(): string | undefined {
    if (value === 0) {
      return "bsModelFormId"
    }
    if (value === 1) {
      return "send-invitation"
    }

    return ""
  }

  const submitInvitation: (form: IForm) => Promise<any> = useCallback(
    async (form: IForm) => {
      if (bsProject?.id) {
        sendBSModelInvitation(formToDto(form, bsProject.id))
          .then(() => {
            refreshBSInput()
          })
          .then(() => {
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION, [bsProjectId]))
          })
      }
    },
    [bsProject, bsProjectId, navigate, refreshBSInput, sendBSModelInvitation]
  )

  const { form, errors, handleChange, handleSubmit } = useForm(
    invitation,
    dtoToForm,
    [required("cognitoUserId")],
    submitInvitation
  )

  const handleChangeOverride = useCallback(
    (cognitoUserId: string | undefined): void => {
      const event = {
        target: {
          id: "cognitoUserId",
          value: cognitoUserId,
        },
      } as unknown as ChangeEvent<HTMLInputElement>
      handleChange(event)
    },
    [handleChange]
  )

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      justifyContent="center"
      flexDirection="column"
      alignContent="top"
      alignItems="center">
      <Box display="flex" justifyContent="center" width="50%">
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          aria-label="Tabs to choose between importing yourself or send invitation to someone"
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: "none" } }}
          textColor="inherit"
          sx={{ my: 3, backgroundColor: "white", borderRadius: 2, height: "1vh" }}>
          <Tab
            label="Je paramètre et importe la maquette"
            id="tab-0"
            sx={{ backgroundColor: value === 0 ? "#E6F0FB" : "", borderRadius: "15px", height: "1vh" }}
          />
          <Tab
            label="J’invite un acteur à paramétrer et importer"
            id="tab-1"
            sx={{
              backgroundColor: value === 1 ? "#E6F0FB" : "",
              borderRadius: "15px",
              height: "1vh",
              cursor: "pointer",
              "&.Mui-disabled": {
                pointerEvents: "auto",
                cursor: "not-allowed",
              },
            }}
          />
        </Tabs>
      </Box>

      {value === 0 && (
        <Grid container columnSpacing={2} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
          <Grid item xs={4} columnGap={2} sx={{ display: "flex", height: "100%" }}>
            <MissingInfoCode />
          </Grid>
          <Grid item xs={6} columnGap={2} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
            {!isModelFileLoaded ? (
              <CircularProgress />
            ) : (
              <BSUploader bsModelFile={bimModelFile} setBsModelFile={setBimModelFile} />
            )}
          </Grid>
        </Grid>
      )}

      {value === 1 && (
        <Grid
          component="form"
          id="send-invitation"
          onSubmit={handleSubmit}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", height: "50vh", width: "100%" }}>
          <Grid item xs={3} />
          <Grid
            container
            item
            xs={6}
            rowGap={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "white",
              boxShadow: 2,
              borderRadius: 8,
              p: 5,
            }}>
            <BSBimModelInviter
              selectedCognitoUserId={form.cognitoUserId}
              handleChange={handleChangeOverride}
              errors={{ ...errors }}
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
      <CustomBottomNavigation
        actionLabel={getBottomButtomLabel()}
        formId={getBottomButtomAction()}
        cancelLabel="Annuler"
        cancelAction={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]))}
        isSubmiting={isModelFileLoading || isModelInvitationLoading}
      />
    </Box>
  )
}
