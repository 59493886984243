import { Button, CircularProgress } from "@mui/material"
import React from "react"

type IProps = {
  label: string
  isSubmitting?: boolean
  isDisabled?: boolean
  fullWidth?: boolean
  onClick(): void
}

export function ValidateButton({
  label,
  isSubmitting,
  isDisabled = false,
  fullWidth,
  onClick,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={onClick}
      disabled={isDisabled || isSubmitting}
      fullWidth={fullWidth}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      {isSubmitting ? <CircularProgress size={24} sx={{ color: "green" }} /> : label}
    </Button>
  )
}
