import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"
import CancelButton from "../buttons/CancelButton/CancelButton"
import { ValidateButton } from "../buttons/ValidateButton/ValidateButton"
import { Transition } from "./BaseDialog"

type IProps = {
  id: string
  title: string
  contentText: string | React.ReactNode
  handleClose: () => void
  action: () => void
  open: boolean
  disabledActionButton?: boolean
}

export function DeleteDialog({
  id,
  title,
  contentText,
  handleClose,
  action,
  open,
  disabledActionButton,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" TransitionComponent={Transition}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={id}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} label="Annuler" />
        <ValidateButton onClick={action} label="Supprimer" isDisabled={disabledActionButton} />
      </DialogActions>
    </Dialog>
  )
}
