import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { BSInputContext } from "../BSInputContext/BSInputContext"
import { BSProjectContext } from "../BSProject/BSProjectContext"

export const BsModelContext = React.createContext<BsModelStore>({} as BsModelStore)

export default function BsModelContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsBimModelId, bsProjectId } = useParams()

  const { bsProject } = useContext(BSProjectContext)
  const { bsInput } = useContext(BSInputContext)

  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const { fetchBimModelById, fetchAllBimModelByProjectId, deleteBSBimModelFile } = useBSBimModel()

  const [bsBimModel, setBsBimModel] = useState<BSBimModel>(new BSBimModel())
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [bsBimModelListByProject, setBsBimModelListByProject] = useState<BSBimModel[]>([])

  const refreshBimModelListByProjectId = useCallback(() => {
    if (bsProjectId) {
      return fetchAllBimModelByProjectId(bsProjectId).then((list) => setBsBimModelListByProject(list))
    }
    return Promise.resolve()
  }, [bsProjectId, fetchAllBimModelByProjectId])

  useEffect(() => {
    refreshBimModelListByProjectId()
  }, [bsProjectId, fetchAllBimModelByProjectId, refreshBimModelListByProjectId])

  useEffect(() => {
    const idToFetch = bsBimModelId && bsBimModelId !== "new" ? bsBimModelId : bsInput?.bsBimModelId ?? ""
    if (idToFetch) {
      fetchBimModelById(idToFetch)
        .then((bimModelResponse) => {
          if (bimModelResponse) {
            setBsBimModel(bimModelResponse)
          } else {
            setBsBimModel(new BSBimModel())
          }
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
    } else if (bsBimModelId === "new") {
      setBsBimModel(new BSBimModel())
    }
  }, [bsBimModelId, bsInput?.bsBimModelId, bsProject, fetchBimModelById, openErrorSnackbar])

  const handleDeleteBsBimModel = useCallback(
    (modelId: string) => {
      if (!isDeleting) {
        setIsDeleting(true)
        return deleteBSBimModelFile(modelId)
          .then(() => refreshBimModelListByProjectId())
          .then(() => openSuccessSnackbar("La maquette a bien été supprimé "))
          .finally(() => {
            setIsDeleting(false)
          })
      }
      return Promise.resolve()
    },
    [deleteBSBimModelFile, isDeleting, openSuccessSnackbar, refreshBimModelListByProjectId]
  )

  const bsModelStore: BsModelStore = useMemo(
    () => ({
      bsBimModel,
      setBsBimModel,
      bsBimModelListByProject,
      setBsBimModelListByProject,
      refreshBimModelListByProjectId,
      handleDeleteBsBimModel,
    }),
    [bsBimModel, bsBimModelListByProject, handleDeleteBsBimModel, refreshBimModelListByProjectId]
  )
  return <BsModelContext.Provider value={bsModelStore}>{children}</BsModelContext.Provider>
}

export type BsModelStore = {
  bsBimModel: BSBimModel
  setBsBimModel: Dispatch<SetStateAction<BSBimModel>>
  bsBimModelListByProject: BSBimModel[]
  setBsBimModelListByProject: Dispatch<SetStateAction<BSBimModel[]>>
  refreshBimModelListByProjectId(): Promise<void>
  handleDeleteBsBimModel(modelId: string): Promise<void>
}
