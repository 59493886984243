import { Box, CircularProgress, Fade, Grid, Typography, Zoom } from "@mui/material"
import { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSBimModelCard } from "../../../components/card/beem-shot/BSBimModelCard"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { pagesUrl } from "../../../core/appConstants"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"

export function BSBimModelList(): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { bsProject } = useContext(BSProjectContext)
  const { organization } = useContext(OrganizationContext)
  const { bsBimModelListByProject } = useContext(BsModelContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <>
      {!bsProject ? (
        <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Fade in timeout={600}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                alignContent="center"
                columnGap={0.5}
                justifyItems="center"
                p={1}>
                <DefaultTypography label="Crée par " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />
                <DefaultTypography
                  label={bsProject?.createdByUser ?? ""}
                  color="#070F27"
                  fontWeight={500}
                  fontSize="11px"
                  lineHeight="16.5px"
                />
                <Typography variant="subtitle1" fontSize={11}>
                  {`· ${organization?.businessName} · `}
                </Typography>

                <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: "#8F8F8F" }}>
                  {` Mise à jour le ${formatToFrench(bsProject?.lastModifiedDate)}`}
                </Typography>
              </Box>
            </Box>

            <Box>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Grid container columnSpacing={4} alignItems="flex-start" height="100%" rowGap={3}>
                  {bsBimModelListByProject.length > 0 ? (
                    bsBimModelListByProject.map((bsBimModel: BSBimModel, index) => (
                      <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={bsBimModel.id}>
                        <Grid item xs={12} md={4} lg={4} key={bsBimModel.id}>
                          <BSBimModelCard bsBimModel={bsBimModel} />
                        </Grid>
                      </Zoom>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      width="100%">
                      <Typography fontSize="24px" fontWeight={600} lineHeight="36px" textAlign="center" color="#374771">
                        Aucun résultat
                      </Typography>
                      <Typography variant="body1">Modifier les options de recherche.</Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Box>
          </Box>
        </Fade>
      )}

      <CustomBottomNavigation
        actionLabel="Ajouter une maquette"
        actionButton={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, "new"]))}
      />
    </>
  )
}
