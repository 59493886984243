import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { FicheConfiguree } from "../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../core/dto/material/MaterialRecord"
import { TypeMaterialEnum } from "../../../../core/enum/typeMaterialEnum"
import { Transition } from "../../../dialog/BaseDialog"
import { BSMaterialLib } from "./BSMaterialLib"

interface IProps {
  selectedBSMaterialResult: BSMaterialResult | undefined
  open: boolean
  actualQuantity: number

  handleCloseMaterialLib(): void
  onSelect(
    selectedRow: IniesRecord | MaterialRecord | FicheConfiguree,
    actualQuantities: number,
    type: TypeMaterialEnum
  ): Promise<void>
}

export function BSMaterialLibDialog({
  handleCloseMaterialLib,
  open,
  actualQuantity,
  selectedBSMaterialResult,
  onSelect,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleCloseMaterialLib}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: 600,
        },
      }}
      TransitionComponent={Transition}>
      <DialogTitle display="flex" justifyContent="center">
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            textAlign: "center",
          }}>
          Importer une déclaration
        </Typography>
      </DialogTitle>
      <DialogContent>
        <BSMaterialLib
          handleCloseMaterialLib={handleCloseMaterialLib}
          selectedBSMaterialResult={selectedBSMaterialResult}
          actualQuantity={actualQuantity}
          onSelect={onSelect}
        />
      </DialogContent>
    </Dialog>
  )
}
