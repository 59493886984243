import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined"
import { Box, CircularProgress, Fade, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DeleteDialog } from "../../../components/dialog/delete-dialog"
import { ImageDisplayer } from "../../../components/image-displayer/image-displayer"
import { TabEnum } from "../../../components/tabs/tabs"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { TextInputTypography } from "../../../components/typography/textInputTypography"
import { pagesUrl } from "../../../core/appConstants"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSCoverImgProjectContext } from "../../../core/context/beem-shot/BSProject/BSCoverImgProjectContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { BSBimModelCard } from "./BSBimModelCard/BSBimModelCard"
import { BSCalculCard } from "./BSCalculCard/BSCalculCard"
import { BSVariantForm } from "./BSVariant/BSVariantForm"

type IProps = {
  handleChange(event: React.SyntheticEvent, newValue: TabEnum): void
  isWritingMode: boolean
}

export function BSVariantDetail({ handleChange, isWritingMode }: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId, bsVariantId } = useParams()
  const navigate = useNavigate()

  const { bsProject } = useContext(BSProjectContext)
  const { organization } = useContext(OrganizationContext)
  const { bsBimModel, refreshBimModelListByProjectId } = useContext(BsModelContext)
  const { selectedVariant, fetchAllBSVariants, deleteVariant } = useContext(BSVariantContext)
  const { file } = useContext(BSCoverImgProjectContext)

  const [openUpdateVariantInfo, setOpenUpdateVariantInfo] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  useEffect(() => {
    if (bsProjectId) {
      fetchAllBSVariants(bsProjectId)
    }
  }, [bsProjectId, fetchAllBSVariants, bsVariantId])

  const handleClose = (): void => {
    setOpenUpdateVariantInfo(false)
  }

  return (
    <>
      {!bsProject ? (
        <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Fade in timeout={600}>
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              py={2}
              alignItems="center"
              alignContent="center"
              columnGap={0.5}
              justifyItems="center">
              <DefaultTypography label="Crée par " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />
              <DefaultTypography
                label={bsProject?.createdByUser ?? ""}
                color="#070F27"
                fontWeight={500}
                fontSize="11px"
                lineHeight="16.5px"
              />
              <Typography variant="subtitle1" fontSize={11}>
                {`· ${organization?.businessName} · `}
              </Typography>

              <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: "#8F8F8F" }}>
                {` Mise à jour le ${formatToFrench(bsProject?.lastModifiedDate)}`}
              </Typography>
            </Box>

            <Grid container display="flex" flexDirection="row" borderRadius={3} sx={{ background: "#fff" }}>
              <Grid item xs={1} />
              <Grid
                container
                item
                xs={10}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                rowGap={2}
                p={3}>
                <Grid item xs={1}>
                  <ImageDisplayer file={file} height={120} />
                </Grid>

                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  flexDirection="row"
                  gap={1}>
                  {selectedVariant?.name}
                </Grid>

                <Grid display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                  <RoomOutlinedIcon />
                  <Typography>
                    {`${bsProject?.address?.street} · ${bsProject?.address?.additional} · ${bsProject?.address?.zipCode} ${bsProject?.address?.city}`}
                  </Typography>
                </Grid>

                <Grid container display="flex" justifyContent="center" columnGap={3}>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="N° d'affaire" />
                    <DefaultTypography
                      label={bsProject?.businessCode ?? ""}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="Surface de référence" />
                    <DefaultTypography
                      label={bsBimModel?.surfaceRef?.toString() || "0"}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="Date du dépôt de permis" />
                    <DefaultTypography
                      label={bsProject?.licenceDate ? formatToFrench(bsProject?.licenceDate) : "Pas de date dépôt..."}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {isWritingMode ? (
                <Grid container item xs={1} display="flex" alignItems="flex-start" flexDirection="row">
                  {selectedVariant && (
                    <Tooltip title="Modifier les informations" placement="bottom" arrow>
                      <IconButton
                        onClick={() => setOpenUpdateVariantInfo(true)}
                        sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {!selectedVariant?.mainVariant && (
                    <Tooltip title="Supprimer la variante" placement="bottom" arrow>
                      <IconButton
                        onClick={() => setOpenDeleteDialog(true)}
                        sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              ) : (
                <Grid item xs={1} />
              )}
            </Grid>

            <Grid container pt={2} display="flex" justifyContent="space-between">
              <Grid
                container
                item
                xs={5.9}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                boxShadow={2}
                borderRadius={2}
                sx={{
                  background: selectedVariant?.calculStatus === CalculStatusEnum.WAITING_FOR_MODEL ? "#fff" : "#E6F0FB",
                  px: 4,
                  pt: 4,
                }}>
                {selectedVariant && (
                  <BSBimModelCard isWritingMode={isWritingMode && bsProject?.status === ProjectStatusEnum.IN_PROGRESS} />
                )}
              </Grid>

              <Grid
                container
                item
                xs={5.9}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                boxShadow={2}
                borderRadius={2}
                sx={{
                  background: "#fff",
                  px: 4,
                  pt: 4,
                }}>
                <BSCalculCard
                  handleChange={handleChange}
                  isWritingMode={isWritingMode && bsProject?.status === ProjectStatusEnum.IN_PROGRESS}
                />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      )}

      <BSVariantForm open={openUpdateVariantInfo} handleClose={handleClose} bsVariant={selectedVariant} isUpdateMode />

      <DeleteDialog
        open={openDeleteDialog}
        id="deleteBSVariant"
        title="Suppression de la variante"
        contentText={`Êtes vous sûr de vouloir supprimer cette variante du projet ${bsProject?.name}`}
        handleClose={() => setOpenDeleteDialog(false)}
        action={() =>
          deleteVariant().then(() => {
            setOpenDeleteDialog(false)
            refreshBimModelListByProjectId()
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]))
          })
        }
      />
    </>
  )
}
