import React, { useEffect, useMemo, useState } from "react"
import { Children } from "../../components/miscellianous/children"
import { Department } from "../enum/departments"
import { useBSProject } from "../hooks/beem-shot/useBSProject"

export const DepartmentContext = React.createContext<DepartmentStore>({} as DepartmentStore)

export function DepartmentContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { fetchDepartments } = useBSProject()

  const [departmentsList, setDepartmentsList] = useState<Department[]>([])

  useEffect(() => {
    fetchDepartments().then((department) =>
      setDepartmentsList(department.sort((a, b) => a.departmentName.localeCompare(b.departmentName)))
    )
  }, [fetchDepartments])

  const DepartmentStore = useMemo(
    () => ({
      deparmentsList: departmentsList,
    }),
    [departmentsList]
  )

  return <DepartmentContext.Provider value={DepartmentStore}>{children}</DepartmentContext.Provider>
}

export type DepartmentStore = { deparmentsList: Department[] }
