import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { fileTypeAccepted } from "../../../../components/image-displayer/upload-image-modal"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { useBSProject } from "../../../hooks/beem-shot/useBSProject"
import { BSProjectContext } from "./BSProjectContext"

export const BSCoverImgProjectContext = React.createContext<BSCoverImgProjectStore>({} as BSCoverImgProjectStore)

export async function createFileFromImage(imagePath: string, fileName: string): Promise<File> {
  const response = await fetch(imagePath)
  const blob = await response.blob()
  return new File([blob], fileName, { type: blob.type })
}

export function BSCoverImgProjectContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProject, refreshProject } = useContext(BSProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)

  const { deleteCoverImage, sendCoverImageFile, fetchCoverImage } = useBSProject()

  const [file, setFile] = useState<File | undefined>()

  const { bsProjectId } = useParams()

  const fetchCoverImgBsProject = useCallback(
    (projectId: string, fileName: string): void => {
      fetchCoverImage(projectId).then(async (blob) => {
        if (blob) {
          const newFile = new File([blob], fileName, { type: fileTypeAccepted })
          setFile(newFile)
        }
      })
    },
    [fetchCoverImage]
  )

  useEffect(() => {
    if (bsProject?.id && bsProject?.hasCoverImg) {
      fetchCoverImgBsProject(bsProject?.id, bsProject?.coverImgFileName)
    } else if (!bsProject) {
      setFile(undefined)
    }
  }, [fetchCoverImgBsProject, bsProject?.id, bsProject?.coverImgFileName, bsProject])

  const createOrUpdateCoverImgBsProject = useCallback(
    (id: string | undefined, tmpFile: File): void => {
      if (tmpFile === undefined) {
        return
      }

      if (id === undefined) {
        openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
        return
      }

      sendCoverImageFile(tmpFile, id)
        .then(() => setFile(tmpFile))
        .then(() => {
          if (bsProjectId) {
            refreshProject()
          }
        })
        .catch((e) => {
          console.error("Erreur lors de l'enregistrement de l'image: ", e)
          setFile(undefined)
        })
    },
    [bsProjectId, openErrorSnackbar, refreshProject, sendCoverImageFile]
  )

  const deleteCoverImgBsProject = useCallback((): void => {
    if (bsProject?.id && bsProject.hasCoverImg) {
      deleteCoverImage(bsProject.id)
        .then(() => refreshProject())
        .then(() => setFile(undefined))
    } else {
      setFile(undefined)
    }
  }, [bsProject?.hasCoverImg, bsProject?.id, deleteCoverImage, refreshProject])

  const bsProjectStore = useMemo(
    () => ({ file, setFile, createOrUpdateCoverImgBsProject, fetchCoverImgBsProject, deleteCoverImgBsProject }),
    [file, createOrUpdateCoverImgBsProject, fetchCoverImgBsProject, deleteCoverImgBsProject]
  )

  return <BSCoverImgProjectContext.Provider value={bsProjectStore}>{children}</BSCoverImgProjectContext.Provider>
}

export type BSCoverImgProjectStore = {
  file: File | undefined
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
  createOrUpdateCoverImgBsProject(id: string | undefined, tmpFile: File): void
  fetchCoverImgBsProject(bsProjectId: string, fileName: string): void
  deleteCoverImgBsProject(): void
}
