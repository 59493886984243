import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"
import TextButton from "../buttons/text-button/TextButton"
import { ValidateButton } from "../buttons/ValidateButton/ValidateButton"
import { TitleFormTypography } from "../typography/title-form-typography"
import { Transition } from "./BaseDialog"

interface IProps {
  titleText: string
  isOpen: boolean
  contentText: React.ReactNode
  cancelButtonLabel: string
  submitButtonLabel: string

  onClose(): void

  onSubmit(): void
}

export function BSSubmitDialog({
  isOpen,
  titleText,
  contentText,
  cancelButtonLabel,
  submitButtonLabel,
  onClose,
  onSubmit,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>
        <TitleFormTypography label={titleText} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Box sx={{ px: 1 }}>
          <TextButton onClick={onClose} label={cancelButtonLabel} />
        </Box>
        <ValidateButton label={submitButtonLabel} onClick={onSubmit} />
      </DialogActions>
    </Dialog>
  )
}
