import { Box, CircularProgress, Paper, Typography } from "@mui/material"
import React, { useContext, useRef, useState } from "react"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { ProjectContext } from "../../../core/context/project/project-context"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { IconFileUpload } from "../../custom-icons/icon-file-upload"

type IProps = {
  onChange: (modelFile: File) => void

  file: File | undefined
  progress: number
  disabled: boolean
  fileType: string
}

export default function FileInput({ onChange, file, progress, disabled, fileType }: IProps): React.JSX.Element {
  const [isDragOver, setIsDragOver] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const dragEnterCount: React.MutableRefObject<number> = useRef<number>(0)
  const { project } = useContext(ProjectContext)
  const { isModelFileLoading, isModelFileLoaded } = useContext(BSModelFileContext)

  function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.files && event.target.files[0]) {
      onChange(event.target.files[0])
    }
  }

  function stopDefaults(e: any): void {
    e.stopPropagation()
    e.preventDefault()
  }

  const dragEvents =
    disabled && project.status !== ProjectStatusEnum.ARCHIVED
      ? {}
      : {
          onMouseEnter(): void {
            setIsMouseOver(true)
          },
          onMouseLeave: () => {
            setIsMouseOver(false)
          },
          onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e)
            dragEnterCount.current += 1
            if (dragEnterCount.current === 1) {
              setIsDragOver(true)
            }
          },
          onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e)
            dragEnterCount.current -= 1
            if (dragEnterCount.current === 0) {
              // Drag has actually left the container
              setIsDragOver(false)
            }
          },
          onDragOver: stopDefaults,
          onDrop: (e: React.DragEvent) => {
            stopDefaults(e)
            setIsDragOver(false)
            dragEnterCount.current = 0
            const files = e?.dataTransfer?.files
            if (files?.length && files[0]) {
              onChange(e.dataTransfer.files[0])
            }
          },
        }

  function getTypographyForInputFile(): JSX.Element {
    if (isModelFileLoading) {
      return <CircularProgress />
    }
    if (file) {
      return <Typography>Fichier prêt à l'envoi</Typography>
    } else if (!disabled) {
      return (
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography fontWeight={600} sx={{ textDecoration: "underline", color: "#08CA8F" }}>
            Télécharger
          </Typography>
          <Typography>ou glissez et déposez le fichier</Typography>
        </Box>
      )
    } else {
      return <Typography>Aucun fichier n'a été chargé</Typography>
    }
  }

  function overOpacity(): number {
    if (file) {
      return 1
    }
    if (isMouseOver || isDragOver) {
      return 1
    }
    return 0.3
  }

  return (
    <Paper
      variant="outlined"
      sx={{ display: "flex", border: file ? "2px solid #0BE2A0" : "2px dashed #cccccc", alignItems: "center" }}>
      <input
        onChange={handleChangeFile}
        style={{ display: "none" }}
        accept={fileType}
        id="file-upload"
        type="file"
        disabled={disabled}
      />
      <Box
        component="label"
        htmlFor="file-upload"
        {...dragEvents}
        sx={{
          width: "100%",
          p: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: disabled ? "" : "pointer",
          textAlign: "center",
          noMouseEvent: {
            pointerEvents: "none",
          },
          opacity: isMouseOver || isDragOver ? 1 : 1.3,
        }}>
        {progress === -1 ? (
          <Box display="flex" gap={3} border={2}>
            {!file && !isModelFileLoading && <IconFileUpload size="large" />}
            {!file && getTypographyForInputFile()}
          </Box>
        ) : (
          <Box>
            {!isModelFileLoading && (progress === 0 || progress === 100) && <IconFileUpload size="large" />}
            {progress !== 100 && file !== undefined && <CircularProgress />}
            {(progress === 0 || progress === 100) && getTypographyForInputFile()}
          </Box>
        )}

        {isModelFileLoading ? (
          <Typography variant="subtitle1" fontWeight={400} sx={{ opacity: 0.3, color: file ? "#0BE2A0" : "" }}>
            Fichier en cours de téléchargement
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ opacity: disabled ? 0 : overOpacity(), color: file ? "#0BE2A0" : "" }}>
            {file ? file.name : `Format attendu ${fileType}`}
          </Typography>
        )}
      </Box>
    </Paper>
  )
}
